import { defineStore } from 'pinia'
import {type RemovableRef, StorageSerializers, useSessionStorage} from "@vueuse/core";
import {BANKID_VERIFICATION_RESULT, EXPIRATION_TIME_LEEWAY} from "~/store/constants/bankID";
import {BankIdApi} from "~/gen/openapi/portalService";
import {TokenSet} from 'openid-client';
interface State {
    requiresBankIDAuthentication: boolean,
    requiresContactInfoChangeApproval: boolean,
    openVerificationModalTrigger: any,
    verificationInProgress: boolean,
    approvalInProgress: boolean,
    bankIDAuthenticationCompleted: boolean,
    tokens: RemovableRef<TokenSet | null>,
    userInfo: any,
    errors: Array<string>,
    personInfoFromBankId: any,
    personInfoFromCrm: any,
}

function isExpired(tokens: TokenSet) {
    return tokens.expires_at && tokens.expires_at * 1000 < Date.now() + EXPIRATION_TIME_LEEWAY;
}

export const useBankIDStore = defineStore('bankID', {
  state: (): State => ({
      requiresBankIDAuthentication: false,
      requiresContactInfoChangeApproval: false,
      openVerificationModalTrigger: 0,
      verificationInProgress: false,
      approvalInProgress: false,
      bankIDAuthenticationCompleted: false,
      tokens: useSessionStorage('pinia/bankID/gmp_bankidTokens', null, {
				serializer: StorageSerializers.object,
			}),
      userInfo: useSessionStorage('pinia/bankID/gmp_userInfo', null, {
				serializer: StorageSerializers.object,
			}),
      errors: [],
      personInfoFromBankId: null,
      personInfoFromCrm: null,
	}),
	getters: {
        isLoggedIn(state) {
            if (!state.tokens || !state.tokens.refresh_token) {
                return false
            }
            const now = new Date().getTime()
            const refreshTokenParsed = JSON.parse(
                atob(state.tokens.refresh_token.split('.')[1])
            )

            return now + EXPIRATION_TIME_LEEWAY < refreshTokenParsed.exp * 1000;
        },
        accessTokenParsed(state) {
            if (!state.tokens || !state.tokens.access_token) {
                return null
            }
            return JSON.parse(atob(state.tokens.access_token.split('.')[1]))
        },
	},
	actions: {
        async checkIfBankIDAuthenticationIsNeeded() {
            if (!this.approvalInProgress && !this.verificationInProgress) {
                const {$axios, $config} = useNuxtApp()
                const bankIdApi = new BankIdApi(undefined, $config.public.apiBaseHost, $axios);
                let response

                try {
                    response = (await bankIdApi.bankIdDoUserNeedToVerifyWithBankId()).data
                } catch (error) {
                    console.error(error)
                } finally {
                    this.requiresBankIDAuthentication = !!response
                }
                return response
            }
        },
        async refresh(refreshToken: string) {
            try {
                const response = await fetch('/bankid/refresh', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                    body: refreshToken,
                })
                if (response.ok) {
                    const tokenResponse = await response.json()
                    this.tokens = tokenResponse.tokens
                    this.userInfo = tokenResponse.userInfo

                    return tokenResponse.tokens
                } else {
                    this.tokens = null
                    this.userInfo = {}
                }
                return undefined
            } catch (e) {
                console.error(e)
                // @ts-ignore
                this.errors = e.response?.data
            }
        },
        async getTokensRefreshIfNeeded() {
            const tokens = this.tokens
            if (tokens && tokens.refresh_token) {
                if (isExpired(tokens)) {
                    return this.refresh(tokens.refresh_token)
                }
                return tokens
            }
        },
        logout() {
            sessionStorage.removeItem('gmp_bankidTokens')
            this.tokens = null
            sessionStorage.removeItem('gmp_userInfo')
            this.userInfo = null
        },
        callback(query: any, $router: any) {
            this.errors = []
            // TODO: handle error here in stead of backend?
            const {$axios} = useNuxtApp()

            // @ts-ignore
            $axios.$get('/bankid/token', {
                    // pre-defined baseURL is set to service-API, override to use local server here
                    // @ts-ignore
                    baseURL: null,
                    params: query,
                })
                .then((response) => {
                    if (!response.tokens.id_token) {
                        throw new Error('No id_token present')
                    }
                    this.tokens = response.tokens
                    this.userInfo = response.userInfo
                    if (response.redirectTo) {
                        const url = new URL(response.redirectTo)
                        $router.push(url.pathname + url.search + url.hash)
                    } else {
                        $router.push('/')
                    }
                })
                .catch((err) => {
                    console.log('Error getting bankid tokens: ', err)
                    if (err?.response?.data?.error === 'access_denied') {
                        this.errors = ['BANKID_CANCELLED']
                    } else {
                        this.errors = ['BANKID_GENERIC_ERROR']
                    }
                    if (err?.response?.data?.redirectTo) {
                        const url = new URL(err?.response?.data?.redirectTo)
                        $router.push(url.pathname + url.search + url.hash)
                    } else {
                        $router.push('/')
                    }
                })
        },
        async syncBankIDInformation() {
            this.errors = [];
            this.openVerificationModalTrigger++
            this.verificationInProgress = true

            const tokens = await this.getTokensRefreshIfNeeded()
            if (!tokens) {
                this.errors = ['BANKID_GENERIC_ERROR']
                this.requiresBankIDAuthentication = true
                return
            }

            const {$axios, $config} = useNuxtApp()
            const bankIdApi = new BankIdApi(undefined, $config.public.apiBaseHost, $axios);
            let response

            try {
                response = (await bankIdApi.bankIdVerifyBankIdInfo({
                    IdToken: tokens.id_token,
                    AccessToken: tokens.access_token,
                })).data

                switch (response.VerificationResult) {
                    case BANKID_VERIFICATION_RESULT.OK:
                    case BANKID_VERIFICATION_RESULT.FIRST_AND_LAST_NAME_DO_NOT_MATCH:
                    case BANKID_VERIFICATION_RESULT.FIRST_NAME_DO_NOT_MATCH:
                    case BANKID_VERIFICATION_RESULT.LAST_NAME_DO_NOT_MATCH: {
                        this.personInfoFromBankId = response.PersonInfoFromBankId
                        this.personInfoFromCrm = response.PersonInfoFromCrm
                        this.requiresContactInfoChangeApproval = true
                        break
                    }
                    case BANKID_VERIFICATION_RESULT.BANKID_CONNECTION_FAILED:
                        this.errors = ['BANKID_CONNECTION_FAILED']
                        this.requiresBankIDAuthentication = true
                        this.logout()
                        break
                    case BANKID_VERIFICATION_RESULT.BANKID_TOKEN_NOT_ACTIVE:
                        // TODO: Implement error message
                        this.errors = ['BANKID_GENERIC_ERROR']
                        this.requiresBankIDAuthentication = true
                        this.logout()
                        break
                    default: {
                        this.requiresBankIDAuthentication = response.VerificationResult !== BANKID_VERIFICATION_RESULT.OK
                    }
                }
            } catch (error) {
                console.error('failed to verify bankid', error)
                this.errors = ['BANKID_GENERIC_ERROR']
                throw error
            } finally {
                this.verificationInProgress = false
            }
        },
        cancelVerification() {
            this.verificationInProgress = false
            this.approvalInProgress = false
            this.requiresContactInfoChangeApproval = false
        },
        async approveUpdatedInformationFromBankID() {
            this.errors = []
            this.approvalInProgress = true

            const tokens = await this.getTokensRefreshIfNeeded()
            if (!tokens) {
                this.errors = ['BANKID_GENERIC_ERROR']
                this.requiresBankIDAuthentication = true
                return
            }

            const {$axios, $config} = useNuxtApp()
            const bankIdApi = new BankIdApi(undefined, $config.public.apiBaseHost, $axios);

            return bankIdApi.bankIdStoreAndVerifyBankIdInfo({
                IdToken: tokens.id_token,
                AccessToken: tokens.access_token,
                PlaceOfLiving: this.personInfoFromBankId?.PlaceOfLiving,
            }).then(() => {
                this.bankIDAuthenticationCompleted = true
                this.requiresBankIDAuthentication = false
            }).catch((e) => {
                console.error('failed to approve updated name from bankId', e)
                this.requiresBankIDAuthentication = true
                this.errors = ['BANKID_GENERIC_ERROR']
                throw e
            }).finally(() => {
                this.requiresContactInfoChangeApproval = false
                this.approvalInProgress = false
            })
        },
	}
})
