import { confirm } from '@gm/components'

export default defineNuxtPlugin((nuxtApp) => {
	// @ts-expect-error
	const { t } = nuxtApp.$i18n;
	const nuxtConfirm = (message: string, options: {name: string; value: boolean;}[]) => {
		const defaultOptions = [
			{
				name: t('YES'),
				value: true,
			},
			{
				name: t('NO'),
				value: false,
			},
		]
		options = options || defaultOptions

		return confirm(message, {
			options: options.map(o => ({
				...o,
				text: o.name,
			}))
		})
	}

	return {
		provide: {
			confirm: nuxtConfirm,
		}
	}
})
