import { default as icons_45pageVo5QlmeCjaMeta } from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as _91year_93DK8DMRWHTiMeta } from "/home/vsts/work/1/s/pages/ac/[accountId]/account/[customerId]/[year].vue?macro=true";
import { default as indexKT3yQqEFYRMeta } from "/home/vsts/work/1/s/pages/ac/[accountId]/account/[customerId]/index.vue?macro=true";
import { default as index1ChPQZdGQRMeta } from "/home/vsts/work/1/s/pages/ac/[accountId]/index.vue?macro=true";
import { default as login_45callbackB4M8YxJkPPMeta } from "/home/vsts/work/1/s/pages/b2c/login-callback.vue?macro=true";
import { default as logout_45callbackXMaSBYNETkMeta } from "/home/vsts/work/1/s/pages/b2c/logout-callback.vue?macro=true";
import { default as callback79ICesKJhvMeta } from "/home/vsts/work/1/s/pages/bankid/callback.vue?macro=true";
import { default as bestill_45skogbruksplanFIhahT3y27Meta } from "/home/vsts/work/1/s/pages/bestill-skogbruksplan.vue?macro=true";
import { default as indextGiUyehp8LMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/files/index.vue?macro=true";
import { default as index5oDyrZ7WC7Meta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/forward-run/index.vue?macro=true";
import { default as indexxj39WZnYm0Meta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/incidents/index.vue?macro=true";
import { default as index0YFKg9k4KxMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/index.vue?macro=true";
import { default as indexzy8RrPhB76Meta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/map-directions/index.vue?macro=true";
import { default as index5MrI67IKkqMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/map/index.vue?macro=true";
import { default as indexFq3ItlbPpiMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/produced/index.vue?macro=true";
import { default as indexEQIKeDokAbMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/reports/index.vue?macro=true";
import { default as assortmentsKBDHFrp4NOMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/[machine]/assortments.vue?macro=true";
import { default as indexO4i2Re0MQeMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/[machine]/index.vue?macro=true";
import { default as reportu4h3E3B4wFMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/[machine]/report.vue?macro=true";
import { default as indexnIMKDEgaikMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/index.vue?macro=true";
import { default as index1ayNYID5F8Meta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-report/[machine]/index.vue?macro=true";
import { default as indexNAgRJO3TMEMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-report/index.vue?macro=true";
import { default as _91orderId_93EPPxkKZOVoMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId].vue?macro=true";
import { default as indexUgH6AcmdZTMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/index.vue?macro=true";
import { default as indexuxvZsUIuwSMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/index.vue?macro=true";
import { default as indexnfaphSBs0zMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/instructions/index.vue?macro=true";
import { default as completelhHSdA4H3xMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/complete.vue?macro=true";
import { default as documentsTESN0CFnDUMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/documents.vue?macro=true";
import { default as index0Lm4G8oTEzMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/index.vue?macro=true";
import { default as map_45directionszp2I8iuZy0Meta } from "/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/map-directions.vue?macro=true";
import { default as mapZJ6fGyjPM7Meta } from "/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/map.vue?macro=true";
import { default as register7Tm2OXQdnGMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/register.vue?macro=true";
import { default as indexCkZfdxwRWmMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/silviculture/index.vue?macro=true";
import { default as _91accountId_93hDcb8nGxuJMeta } from "/home/vsts/work/1/s/pages/co/[accountId].vue?macro=true";
import { default as instructionsOoPtbW8vjmMeta } from "/home/vsts/work/1/s/pages/co/instructions.vue?macro=true";
import { default as _91year_93cnH6g9KVVaMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/accounting/[year].vue?macro=true";
import { default as indexnHBHBOEnSOMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/accounting/index.vue?macro=true";
import { default as indexsRMX5Bm9v0Meta } from "/home/vsts/work/1/s/pages/fo/[accountId]/contact/index.vue?macro=true";
import { default as _91contractId_93rn398VEcVSMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/contracts/[contractId].vue?macro=true";
import { default as indexEoNV0sB6EfMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/contracts/index.vue?macro=true";
import { default as indexDl1Rxqr80vMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/documents/index.vue?macro=true";
import { default as _91agreementId_93CzqRYy8DXVMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/environmental-agreements/[agreementId].vue?macro=true";
import { default as indexfresNwFOAQMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/index.vue?macro=true";
import { default as EnvironmentReportForm8GeZW46YUOMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/orders/[orderId]/EnvironmentReportForm.vue?macro=true";
import { default as indexjKnTKRrEBOMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/orders/[orderId]/index.vue?macro=true";
import { default as track_45damage_45repairNld0SWH2iAMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/orders/[orderId]/track-damage-repair.vue?macro=true";
import { default as indexedBFTwVMbGMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/orders/index.vue?macro=true";
import { default as unsigned_45documentsj6E0AMbV55Meta } from "/home/vsts/work/1/s/pages/fo/unsigned-documents.vue?macro=true";
import { default as indexegOWm4p6gtMeta } from "/home/vsts/work/1/s/pages/index.vue?macro=true";
import { default as index47X38cQUTWMeta } from "/home/vsts/work/1/s/pages/my-account/ac/[accountId]/index.vue?macro=true";
import { default as indexXE6f9kj0nTMeta } from "/home/vsts/work/1/s/pages/my-account/ac/[accountId]/users/index.vue?macro=true";
import { default as indexubamDXgra7Meta } from "/home/vsts/work/1/s/pages/my-account/co/[accountId]/index.vue?macro=true";
import { default as indexfxbTnMxh5WMeta } from "/home/vsts/work/1/s/pages/my-account/co/[accountId]/teams/index.vue?macro=true";
import { default as indexNi6ZMqVckXMeta } from "/home/vsts/work/1/s/pages/my-account/co/[accountId]/users/index.vue?macro=true";
import { default as index2CeLTFXXoGMeta } from "/home/vsts/work/1/s/pages/my-account/consent/index.vue?macro=true";
import { default as indexRZWtEnEPyZMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/accountant/index.vue?macro=true";
import { default as indexzjDfruYZo9Meta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/environmental-information/index.vue?macro=true";
import { default as indexwI63V0WfR4Meta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/general/index.vue?macro=true";
import { default as indexAKK9InaRQjMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/index.vue?macro=true";
import { default as indexIy9ReAtWrjMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/properties/index.vue?macro=true";
import { default as delegate_45powers_45of_45attorney6vdi0Nd4IpMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/delegate-powers-of-attorney.vue?macro=true";
import { default as index3KxjouWk5DMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/index.vue?macro=true";
import { default as approveU5OdDMrAiHMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/[delegationRefCode]/approve.vue?macro=true";
import { default as delete87WaIe3UpuMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/[delegationRefCode]/delete.vue?macro=true";
import { default as editSxE3W377SYMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/[delegationRefCode]/edit.vue?macro=true";
import { default as delegatentP2qPJBkxMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/delegate.vue?macro=true";
import { default as cancel6rwFaNfi4AMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/right-of-use/[delegationRefCode]/cancel.vue?macro=true";
import { default as delegatezsj9dAp42yMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/right-of-use/delegate.vue?macro=true";
import { default as indexa85hMxqlgLMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/shareholder-information/index.vue?macro=true";
import { default as indexsczzrF0tvJMeta } from "/home/vsts/work/1/s/pages/my-account/index.vue?macro=true";
import { default as indexwZt15u7mLPMeta } from "/home/vsts/work/1/s/pages/my-account/pl/[accountId]/index.vue?macro=true";
import { default as indexv3i41LpmLdMeta } from "/home/vsts/work/1/s/pages/my-account/settings/index.vue?macro=true";
import { default as indexi2AH5rITSjMeta } from "/home/vsts/work/1/s/pages/my-account/user-access/index.vue?macro=true";
import { default as deletemM9FK8OtbMMeta } from "/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/power-of-attorney/[delegationRefCode]/delete.vue?macro=true";
import { default as cancelcZXXwthO27Meta } from "/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/right-of-use/[delegationRefCode]/cancel.vue?macro=true";
import { default as delegateEX9vLUWoSWMeta } from "/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/right-of-use/delegate.vue?macro=true";
import { default as right_45of_45useCFZRwMaNRhMeta } from "/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/right-of-use.vue?macro=true";
import { default as settingsNegSHcgjf9Meta } from "/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/settings.vue?macro=true";
import { default as request_45power_45of_45attorneyjl9aXVwannMeta } from "/home/vsts/work/1/s/pages/my-account/user-access/request-power-of-attorney.vue?macro=true";
import { default as indexDrvqEpOdXPMeta } from "/home/vsts/work/1/s/pages/pl/[accountId]/[orderId]/index.vue?macro=true";
import { default as indexmrq2AU8uSSMeta } from "/home/vsts/work/1/s/pages/pl/[accountId]/index.vue?macro=true";
import { default as indexSPUwK8hX2XMeta } from "/home/vsts/work/1/s/pages/redirect/po/[orderId]/index.vue?macro=true";
import { default as about_45you3cnxpxlg9VMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/about-you.vue?macro=true";
import { default as allmaQqmKczeZJxMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/allma.vue?macro=true";
import { default as bank_45accountQJZwwjn9unMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/bank-account.vue?macro=true";
import { default as companyGzjSrO8c5zMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/company.vue?macro=true";
import { default as confirm_45accesspNNJc5Eb1fMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/confirm-access.vue?macro=true";
import { default as contact_45infoNRe0zdeDIaMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/contact-info.vue?macro=true";
import { default as doneEl8HZk0GAmMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/done.vue?macro=true";
import { default as indexMMCxdU4DnqMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/index.vue?macro=true";
import { default as loginNgoBmSGIsDMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/login.vue?macro=true";
import { default as share_45holderIjqxbyFJafMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/share-holder.vue?macro=true";
import { default as skog_45subscriptionQlboR2xQxQMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/skog-subscription.vue?macro=true";
import { default as summary1bRY4SAkTEMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/summary.vue?macro=true";
import { default as transferouFCpwaFK6Meta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/transfer.vue?macro=true";
import { default as new_45forest_45ownerUJPFZzQS9OMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner.vue?macro=true";
import { default as completing9s3xVTGWnvMeta } from "/home/vsts/work/1/s/pages/register/simple/[contactId]/completing.vue?macro=true";
import { default as confirm_45accessQ0qM8yfN8TMeta } from "/home/vsts/work/1/s/pages/register/simple/[contactId]/confirm-access.vue?macro=true";
import { default as contact_45info33Xpt4OAiuMeta } from "/home/vsts/work/1/s/pages/register/simple/[contactId]/contact-info.vue?macro=true";
import { default as indexbSlhr0YLhdMeta } from "/home/vsts/work/1/s/pages/register/simple/[contactId]/index.vue?macro=true";
import { default as loginN3kmuGy4jUMeta } from "/home/vsts/work/1/s/pages/register/simple/[contactId]/login.vue?macro=true";
import { default as success5qIGyo5CrhMeta } from "/home/vsts/work/1/s/pages/register/simple/[contactId]/success.vue?macro=true";
import { default as summaryhkwDxZ58flMeta } from "/home/vsts/work/1/s/pages/register/simple/[contactId]/summary.vue?macro=true";
import { default as _91contactId_93EVjc4TGvkWMeta } from "/home/vsts/work/1/s/pages/register/simple/[contactId].vue?macro=true";
import { default as _91id_93Jyh7p3Drg3Meta } from "/home/vsts/work/1/s/pages/resetpassword/[id].vue?macro=true";
export default [
  {
    name: "icons-page",
    path: "/_icons",
    meta: {"layout":"svg-sprite"},
    component: () => import("/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: "ac-accountId-account-customerId-year",
    path: "/ac/:accountId()/account/:customerId()/:year()",
    component: () => import("/home/vsts/work/1/s/pages/ac/[accountId]/account/[customerId]/[year].vue").then(m => m.default || m)
  },
  {
    name: "ac-accountId-account-customerId",
    path: "/ac/:accountId()/account/:customerId()",
    component: () => import("/home/vsts/work/1/s/pages/ac/[accountId]/account/[customerId]/index.vue").then(m => m.default || m)
  },
  {
    name: "ac-accountId",
    path: "/ac/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/ac/[accountId]/index.vue").then(m => m.default || m)
  },
  {
    name: "b2c-login-callback",
    path: "/b2c/login-callback",
    meta: login_45callbackB4M8YxJkPPMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/b2c/login-callback.vue").then(m => m.default || m)
  },
  {
    name: "b2c-logout-callback",
    path: "/b2c/logout-callback",
    meta: logout_45callbackXMaSBYNETkMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/b2c/logout-callback.vue").then(m => m.default || m)
  },
  {
    name: "bankid-callback",
    path: "/bankid/callback",
    meta: callback79ICesKJhvMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/bankid/callback.vue").then(m => m.default || m)
  },
  {
    name: "bestill-skogbruksplan",
    path: "/bestill-skogbruksplan",
    meta: bestill_45skogbruksplanFIhahT3y27Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/bestill-skogbruksplan.vue").then(m => m.default || m)
  },
  {
    name: _91accountId_93hDcb8nGxuJMeta?.name,
    path: "/co/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId].vue").then(m => m.default || m),
    children: [
  {
    name: _91orderId_93EPPxkKZOVoMeta?.name,
    path: "felling/:orderId()",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId].vue").then(m => m.default || m),
    children: [
  {
    name: "co-accountId-felling-orderId-files",
    path: "files",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/files/index.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-felling-orderId-forward-run",
    path: "forward-run",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/forward-run/index.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-felling-orderId-incidents",
    path: "incidents",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/incidents/index.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-felling-orderId",
    path: "",
    meta: index0YFKg9k4KxMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-felling-orderId-map-directions",
    path: "map-directions",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/map-directions/index.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-felling-orderId-map",
    path: "map",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/map/index.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-felling-orderId-produced",
    path: "produced",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/produced/index.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-felling-orderId-reports",
    path: "reports",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-felling-orderId-work-order-machine-assortments",
    path: "work-order/:machine()/assortments",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/[machine]/assortments.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-felling-orderId-work-order-machine",
    path: "work-order/:machine()",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/[machine]/index.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-felling-orderId-work-order-machine-report",
    path: "work-order/:machine()/report",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/[machine]/report.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-felling-orderId-work-order",
    path: "work-order",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/index.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-felling-orderId-work-report-machine",
    path: "work-report/:machine()",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-report/[machine]/index.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-felling-orderId-work-report",
    path: "work-report",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-report/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "co-accountId-felling",
    path: "felling",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/index.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/index.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-instructions",
    path: "instructions",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/instructions/index.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-silviculture-orderId-complete",
    path: "silviculture/:orderId()/complete",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/complete.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-silviculture-orderId-documents",
    path: "silviculture/:orderId()/documents",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/documents.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-silviculture-orderId",
    path: "silviculture/:orderId()",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-silviculture-orderId-map-directions",
    path: "silviculture/:orderId()/map-directions",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/map-directions.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-silviculture-orderId-map",
    path: "silviculture/:orderId()/map",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/map.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-silviculture-orderId-register",
    path: "silviculture/:orderId()/register",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/register.vue").then(m => m.default || m)
  },
  {
    name: "co-accountId-silviculture",
    path: "silviculture",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/silviculture/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "co-instructions",
    path: "/co/instructions",
    meta: instructionsOoPtbW8vjmMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/co/instructions.vue").then(m => m.default || m)
  },
  {
    name: "fo-accountId-accounting-year",
    path: "/fo/:accountId()/accounting/:year()",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/accounting/[year].vue").then(m => m.default || m)
  },
  {
    name: "fo-accountId-accounting",
    path: "/fo/:accountId()/accounting",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/accounting/index.vue").then(m => m.default || m)
  },
  {
    name: "fo-accountId-contact",
    path: "/fo/:accountId()/contact",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "fo-accountId-contracts-contractId",
    path: "/fo/:accountId()/contracts/:contractId()",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/contracts/[contractId].vue").then(m => m.default || m)
  },
  {
    name: "fo-accountId-contracts",
    path: "/fo/:accountId()/contracts",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/contracts/index.vue").then(m => m.default || m)
  },
  {
    name: "fo-accountId-documents",
    path: "/fo/:accountId()/documents",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "fo-accountId-environmental-agreements-agreementId",
    path: "/fo/:accountId()/environmental-agreements/:agreementId()",
    meta: _91agreementId_93CzqRYy8DXVMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/environmental-agreements/[agreementId].vue").then(m => m.default || m)
  },
  {
    name: "fo-accountId",
    path: "/fo/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/index.vue").then(m => m.default || m)
  },
  {
    name: "fo-accountId-orders-orderId-EnvironmentReportForm",
    path: "/fo/:accountId()/orders/:orderId()/EnvironmentReportForm",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/orders/[orderId]/EnvironmentReportForm.vue").then(m => m.default || m)
  },
  {
    name: "fo-accountId-orders-orderId",
    path: "/fo/:accountId()/orders/:orderId()",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/orders/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "fo-accountId-orders-orderId-track-damage-repair",
    path: "/fo/:accountId()/orders/:orderId()/track-damage-repair",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/orders/[orderId]/track-damage-repair.vue").then(m => m.default || m)
  },
  {
    name: "fo-accountId-orders",
    path: "/fo/:accountId()/orders",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "fo-unsigned-documents",
    path: "/fo/unsigned-documents",
    component: () => import("/home/vsts/work/1/s/pages/fo/unsigned-documents.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-ac-accountId",
    path: "/my-account/ac/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/my-account/ac/[accountId]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-ac-accountId-users",
    path: "/my-account/ac/:accountId()/users",
    component: () => import("/home/vsts/work/1/s/pages/my-account/ac/[accountId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-co-accountId",
    path: "/my-account/co/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/my-account/co/[accountId]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-co-accountId-teams",
    path: "/my-account/co/:accountId()/teams",
    component: () => import("/home/vsts/work/1/s/pages/my-account/co/[accountId]/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-co-accountId-users",
    path: "/my-account/co/:accountId()/users",
    component: () => import("/home/vsts/work/1/s/pages/my-account/co/[accountId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-consent",
    path: "/my-account/consent",
    component: () => import("/home/vsts/work/1/s/pages/my-account/consent/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-fo-accountId-accountant",
    path: "/my-account/fo/:accountId()/accountant",
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/accountant/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-fo-accountId-environmental-information",
    path: "/my-account/fo/:accountId()/environmental-information",
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/environmental-information/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-fo-accountId-general",
    path: "/my-account/fo/:accountId()/general",
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/general/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-fo-accountId",
    path: "/my-account/fo/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-fo-accountId-properties",
    path: "/my-account/fo/:accountId()/properties",
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/properties/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-fo-accountId-property-legal-rights-delegate-powers-of-attorney",
    path: "/my-account/fo/:accountId()/property-legal-rights/delegate-powers-of-attorney",
    meta: delegate_45powers_45of_45attorney6vdi0Nd4IpMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/delegate-powers-of-attorney.vue").then(m => m.default || m)
  },
  {
    name: "my-account-fo-accountId-property-legal-rights",
    path: "/my-account/fo/:accountId()/property-legal-rights",
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-fo-accountId-property-legal-rights-property-propertyId-power-of-attorney-delegationRefCode-approve",
    path: "/my-account/fo/:accountId()/property-legal-rights/property/:propertyId()/power-of-attorney/:delegationRefCode()/approve",
    meta: approveU5OdDMrAiHMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/[delegationRefCode]/approve.vue").then(m => m.default || m)
  },
  {
    name: "my-account-fo-accountId-property-legal-rights-property-propertyId-power-of-attorney-delegationRefCode-delete",
    path: "/my-account/fo/:accountId()/property-legal-rights/property/:propertyId()/power-of-attorney/:delegationRefCode()/delete",
    meta: delete87WaIe3UpuMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/[delegationRefCode]/delete.vue").then(m => m.default || m)
  },
  {
    name: "my-account-fo-accountId-property-legal-rights-property-propertyId-power-of-attorney-delegationRefCode-edit",
    path: "/my-account/fo/:accountId()/property-legal-rights/property/:propertyId()/power-of-attorney/:delegationRefCode()/edit",
    meta: editSxE3W377SYMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/[delegationRefCode]/edit.vue").then(m => m.default || m)
  },
  {
    name: "my-account-fo-accountId-property-legal-rights-property-propertyId-power-of-attorney-delegate",
    path: "/my-account/fo/:accountId()/property-legal-rights/property/:propertyId()/power-of-attorney/delegate",
    meta: delegatentP2qPJBkxMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/delegate.vue").then(m => m.default || m)
  },
  {
    name: "my-account-fo-accountId-property-legal-rights-property-propertyId-right-of-use-delegationRefCode-cancel",
    path: "/my-account/fo/:accountId()/property-legal-rights/property/:propertyId()/right-of-use/:delegationRefCode()/cancel",
    meta: cancel6rwFaNfi4AMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/right-of-use/[delegationRefCode]/cancel.vue").then(m => m.default || m)
  },
  {
    name: "my-account-fo-accountId-property-legal-rights-property-propertyId-right-of-use-delegate",
    path: "/my-account/fo/:accountId()/property-legal-rights/property/:propertyId()/right-of-use/delegate",
    meta: delegatezsj9dAp42yMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/right-of-use/delegate.vue").then(m => m.default || m)
  },
  {
    name: "my-account-fo-accountId-shareholder-information",
    path: "/my-account/fo/:accountId()/shareholder-information",
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/shareholder-information/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account",
    path: "/my-account",
    component: () => import("/home/vsts/work/1/s/pages/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-pl-accountId",
    path: "/my-account/pl/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/my-account/pl/[accountId]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-settings",
    path: "/my-account/settings",
    component: () => import("/home/vsts/work/1/s/pages/my-account/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-user-access",
    path: "/my-account/user-access",
    component: () => import("/home/vsts/work/1/s/pages/my-account/user-access/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-user-access-property-propertyId-power-of-attorney-delegationRefCode-delete",
    path: "/my-account/user-access/property/:propertyId()/power-of-attorney/:delegationRefCode()/delete",
    meta: deletemM9FK8OtbMMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/power-of-attorney/[delegationRefCode]/delete.vue").then(m => m.default || m)
  },
  {
    name: "my-account-user-access-property-propertyId-right-of-use",
    path: "/my-account/user-access/property/:propertyId()/right-of-use",
    meta: right_45of_45useCFZRwMaNRhMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/right-of-use.vue").then(m => m.default || m),
    children: [
  {
    name: "my-account-user-access-property-propertyId-right-of-use-delegationRefCode-cancel",
    path: ":delegationRefCode()/cancel",
    meta: cancelcZXXwthO27Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/right-of-use/[delegationRefCode]/cancel.vue").then(m => m.default || m)
  },
  {
    name: "my-account-user-access-property-propertyId-right-of-use-delegate",
    path: "delegate",
    meta: delegateEX9vLUWoSWMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/right-of-use/delegate.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "my-account-user-access-property-propertyId-settings",
    path: "/my-account/user-access/property/:propertyId()/settings",
    meta: settingsNegSHcgjf9Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/settings.vue").then(m => m.default || m)
  },
  {
    name: "my-account-user-access-request-power-of-attorney",
    path: "/my-account/user-access/request-power-of-attorney",
    meta: request_45power_45of_45attorneyjl9aXVwannMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/user-access/request-power-of-attorney.vue").then(m => m.default || m)
  },
  {
    name: "pl-accountId-orderId",
    path: "/pl/:accountId()/:orderId()",
    component: () => import("/home/vsts/work/1/s/pages/pl/[accountId]/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "pl-accountId",
    path: "/pl/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/pl/[accountId]/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect-po-orderId",
    path: "/redirect/po/:orderId()",
    component: () => import("/home/vsts/work/1/s/pages/redirect/po/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: new_45forest_45ownerUJPFZzQS9OMeta?.name,
    path: "/register/new-forest-owner",
    meta: new_45forest_45ownerUJPFZzQS9OMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner.vue").then(m => m.default || m),
    children: [
  {
    name: "register-new-forest-owner-about-you",
    path: "about-you",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/about-you.vue").then(m => m.default || m)
  },
  {
    name: "register-new-forest-owner-allma",
    path: "allma",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/allma.vue").then(m => m.default || m)
  },
  {
    name: "register-new-forest-owner-bank-account",
    path: "bank-account",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/bank-account.vue").then(m => m.default || m)
  },
  {
    name: "register-new-forest-owner-company",
    path: "company",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/company.vue").then(m => m.default || m)
  },
  {
    name: "register-new-forest-owner-confirm-access",
    path: "confirm-access",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/confirm-access.vue").then(m => m.default || m)
  },
  {
    name: "register-new-forest-owner-contact-info",
    path: "contact-info",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/contact-info.vue").then(m => m.default || m)
  },
  {
    name: "register-new-forest-owner-done",
    path: "done",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/done.vue").then(m => m.default || m)
  },
  {
    name: "register-new-forest-owner",
    path: "",
    meta: indexMMCxdU4DnqMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/index.vue").then(m => m.default || m)
  },
  {
    name: "register-new-forest-owner-login",
    path: "login",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/login.vue").then(m => m.default || m)
  },
  {
    name: "register-new-forest-owner-share-holder",
    path: "share-holder",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/share-holder.vue").then(m => m.default || m)
  },
  {
    name: "register-new-forest-owner-skog-subscription",
    path: "skog-subscription",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/skog-subscription.vue").then(m => m.default || m)
  },
  {
    name: "register-new-forest-owner-summary",
    path: "summary",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/summary.vue").then(m => m.default || m)
  },
  {
    name: "register-new-forest-owner-transfer",
    path: "transfer",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/transfer.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91contactId_93EVjc4TGvkWMeta?.name,
    path: "/register/simple/:contactId()",
    meta: _91contactId_93EVjc4TGvkWMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId].vue").then(m => m.default || m),
    children: [
  {
    name: "register-simple-contactId-completing",
    path: "completing",
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId]/completing.vue").then(m => m.default || m)
  },
  {
    name: "register-simple-contactId-confirm-access",
    path: "confirm-access",
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId]/confirm-access.vue").then(m => m.default || m)
  },
  {
    name: "register-simple-contactId-contact-info",
    path: "contact-info",
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId]/contact-info.vue").then(m => m.default || m)
  },
  {
    name: "register-simple-contactId",
    path: "",
    meta: indexbSlhr0YLhdMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId]/index.vue").then(m => m.default || m)
  },
  {
    name: "register-simple-contactId-login",
    path: "login",
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId]/login.vue").then(m => m.default || m)
  },
  {
    name: "register-simple-contactId-success",
    path: "success",
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId]/success.vue").then(m => m.default || m)
  },
  {
    name: "register-simple-contactId-summary",
    path: "summary",
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId]/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "resetpassword-id",
    path: "/resetpassword/:id()",
    meta: _91id_93Jyh7p3Drg3Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/resetpassword/[id].vue").then(m => m.default || m)
  }
]