import Axios, {type AxiosRequestConfig, type AxiosStatic} from 'axios';
import {useUserStore} from '~/stores/user';

// keep old nuxt-module syntax and shortcut for getting data
export interface AxiosInstanceWithExtra extends AxiosStatic {
    $get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    $post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
    $put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
    $delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
}

export default defineNuxtPlugin((nuxtApp) => {
    const axiosInstance = Axios.create({
        headers: {
            common: {
                'Content-Type': 'application/json',
                'B2C-Auth': 'On',
            }
        },
        withCredentials: true,
    }) as AxiosInstanceWithExtra

    axiosInstance.$get = async function () {
        // @ts-ignore
        return (await this.get.apply(this, arguments)).data
    }
    axiosInstance.$put = async function () {
        // @ts-ignore
        return (await this.put.apply(this, arguments)).data
    }
    axiosInstance.$post = async function () {
        // @ts-ignore
        return (await this.post.apply(this, arguments)).data
    }
    axiosInstance.$delete = async function () {
        // @ts-ignore
        return (await this.delete.apply(this, arguments)).data
    }

    // retry login
    axiosInstance.interceptors.response.use(response => {
        const userStore = useUserStore()
        userStore.isCheckingLoginStatus = false
        return Promise.resolve(response)
    }, async error => {
        const attempt = parseInt(error.config.headers.get('X-Attempt') || 0)
        if (attempt < 3
            && error.config
            && error.response
            && error.response.status === 401
            && !error.config.url?.endsWith(`/auth/refresh`)
        ) {
            const userStore = useUserStore()
            try {
                await userStore.checkLogin(true);
                error.config.headers.set('X-Attempt', attempt + 1)
                return axiosInstance.request(error.config)
            } catch (_err) {
                userStore.trigLogout()
                return Promise.reject(error)
            }
        } else {
            return Promise.reject(error)
        }
    })

    return {
        provide: {
            axios: axiosInstance,
        }
    }
})
