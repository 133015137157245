import { defineStore } from 'pinia'
import {type RemovableRef, useLocalStorage} from '@vueuse/core';

interface State {
    locales: Array<any>,
    localeOptions: Array<{name: string, value: string, lang: string, flag: string}>
    locale: RemovableRef<string>,
}

export const useLocaleStore = defineStore('locale', {
  state: (): State => ({
      locales: ['no', 'en'],
      localeOptions: [
          {
              name: 'Norsk',
              value: 'no',
              lang: 'no',
              flag: '🇳🇴',
          },
          {
              name: 'English',
              value: 'en',
              lang: 'en',
              flag: '🇬🇧',
          },
      ],
      locale: useLocalStorage('pinia/locale/locale', ''),
	}),
	getters: {

	},
	actions: {
        SET_LANG(locale: any) {
            if (this.locales.includes(locale)) {
                this.locale = locale
            }
        },
	}
})
