import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { useUserStore } from '@/stores/user'
import { useAccountStore } from '~/stores/account'


export default defineNuxtPlugin((nuxtApp) => {
    const appInsightsEnabled = nuxtApp.$config.public.features.applicationInsights
    const appInsights = new ApplicationInsights({
        config: {
            connectionString: nuxtApp.$config.public.applicationInsightsConnectionString,
            loggingLevelConsole: 2,
            loggingLevelTelemetry: 2,
            correlationHeaderDomains: ['*.glommen-mjosen.no'],
            enableCorsCorrelation: true,
            disableAjaxTracking: false,
            disableCorrelationHeaders: false,
            disableFetchTracking: false,
        }
    })


    appInsights.addTelemetryInitializer(envelope => {
        const userStore = useUserStore()
        const accountStore = useAccountStore()

        if ((envelope?.baseData?.target as String)?.includes('login-callback')) {
            return false
        }

        if (envelope?.data) {
            if (userStore.userId) {
                envelope.data.mail = userStore.userId
            }
            if (accountStore.activeAccount) {
                envelope.data.accountId = accountStore.activeAccount
            }
        }

        return !!appInsightsEnabled
    })

    appInsights.loadAppInsights();

    return {
        provide: {
            appInsights
        }
    }
})