export const ROLE_FOREST_OWNER = 1
export const ROLE_CONTRACTOR = 2
export const ROLE_PLANT_DELIVERY = 3
export const ROLE_ACCOUNTANT = 4

export const CUSTOMER_ROLES = [
	{
		name: 'FOREST_OWNER_ACCOUNT',
		path: 'fo',
		id: ROLE_FOREST_OWNER,
	},
	{
		name: 'CONTRACTOR',
		path: 'co',
		id: ROLE_CONTRACTOR,
	},
	{
		name: 'PLANT_DELIVERY',
		path: 'pl',
		id: ROLE_PLANT_DELIVERY,
	},
	{
		name: 'ACCOUNTANT',
		path: 'ac',
		id: ROLE_ACCOUNTANT,
	},
]

export const CUSTOMER_TYPES = {
	shareholder: [200000],
	hasAccountant: [200000, 200003],
}
