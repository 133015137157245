<template>
  <NuxtLayout name="default">
    <div class="error-page">
      <div class="error-page__content">
        <i class="icon icon--red--faded">
          <svg-icon width="96" height="96" name="error" /></i>
        <h1 class="error" v-if="error.statusCode === 404">
          {{ $t('ERROR_PAGE_NOT_FOUND') }}
        </h1>
        <h1 class="error" v-else>
          {{ $t('ERROR_GENERIC_CODE', { code: error.statusCode }) }}
        </h1>
        <nuxt-link to="/">{{ $t('FRONTPAGE') }}</nuxt-link>
      </div>
    </div>
  </NuxtLayout>
</template>

<script>
export default {
	props: ['error'],
}
</script>
