/**
 * Plugin to override vue-i18n $t and $te.
 * workaround for https://github.com/intlify/vue-i18n-next/issues/1155
 */
export default defineNuxtPlugin((nuxt) => {
    // @ts-expect-error
    const { t, te } = nuxt.$i18n;

    const nullSafeT = (key?: string | null, ...args: any[]): string => {
        if (key == null) {
            return '';
        }
        return t(key, ...args);
    }

    const nullSafeTe = (key?: string | null, ...args: any[]): boolean => {
        if (key == null) {
            return false;
        }
        return te(key, ...args);
    }

    return {
        provide: {
            t: nullSafeT,
            te: nullSafeTe,
        }
    }
})
