// VerificationResult ENUM
export const BANKID_VERIFICATION_RESULT = {
    OK: 1,
    FIRST_AND_LAST_NAME_DO_NOT_MATCH: 2,
    BANKID_TOKEN_NOT_ACTIVE: 3,
    BANKID_CONNECTION_FAILED: 4,
    FIRST_NAME_DO_NOT_MATCH: 5,
    LAST_NAME_DO_NOT_MATCH: 6
};

export const EXPIRATION_TIME_LEEWAY = 20 * 1000;
