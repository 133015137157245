import {
  getCurrentScope,
  init,
  captureConsoleIntegration, browserTracingIntegration, replayIntegration
} from '@sentry/vue'
import { useRouter } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  if (nuxtApp.$config.public.environment && !nuxtApp.$config.public.environment.toLowerCase().includes('dev')) {
    console.log('Sentry is active')
    const router = useRouter()
    init({
      app: nuxtApp.vueApp,
      dsn: nuxtApp.$config.public.sentryDsn,
      integrations: [
          captureConsoleIntegration({
            levels: ['error']
          }),
        browserTracingIntegration({
          router: router
          })
      ],
      ignoreErrors: [
        "Auth error, method:",
        "Error: Request failed with status code 401",
        "interaction_in_progress"
      ],
      environment: nuxtApp.$config.public.environment,
      release: `${nuxtApp.$config.public.version}:${nuxtApp.$config.public.environment}`,
      tracesSampleRate: 0.6,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })

    {
      const scope = getCurrentScope();
      try {
        const user = JSON.parse(
            (window as any).localStorage.getItem('pinia/user/userData')
        )
        if (user) {
          scope.setUser({
            email: user.email,
            id: user.crmid,
            username: user.userid
          })
        }
      } catch (e) {
        console.warn('Error configuring sentry scope', e)
      }
    }
  }
})
