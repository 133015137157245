// @ts-ignore
import { VueMaskDirective } from "v-mask";

// https://github.com/probil/v-mask/issues/498#issuecomment-827027834
const vMaskV2 = VueMaskDirective
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind,
}

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('mask', vMaskV3)
})
