/* tslint:disable */
/* eslint-disable */
/**
 * mjosenskogService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BankIdIdTokensRequestDto } from '../model';
// @ts-ignore
import { BankIdPersonInfoDto } from '../model';
// @ts-ignore
import { BankIdVerificationDto } from '../model';
// @ts-ignore
import { BankIdVerificationModel } from '../model';
// @ts-ignore
import { CancelDelegationOutput } from '../model';
// @ts-ignore
import { ContactModelComLib } from '../model';
// @ts-ignore
import { CreatePowerOfAttorneyOutput } from '../model';
// @ts-ignore
import { EssentialPersonInfoResponse } from '../model';
// @ts-ignore
import { GetJuristicInfoFromBRREGInput } from '../model';
// @ts-ignore
import { GetLinkVerifiedSigningInput } from '../model';
// @ts-ignore
import { GetLinkVerifiedSigningOutput } from '../model';
// @ts-ignore
import { GetPersonInfoFromEmailDto } from '../model';
// @ts-ignore
import { GetPowerOfAttorneyOutput } from '../model';
// @ts-ignore
import { GetPowersOfAttorneyContactOutput } from '../model';
// @ts-ignore
import { GetPropertyInfoRequestDto } from '../model';
// @ts-ignore
import { GetPropertyInfoResponseDto } from '../model';
// @ts-ignore
import { GetRightOfUseListInput } from '../model';
// @ts-ignore
import { GetRightOfUseListOutput } from '../model';
// @ts-ignore
import { PermissionModel } from '../model';
// @ts-ignore
import { SetAccountWithPropertyRightOfUseInputRest } from '../model';
// @ts-ignore
import { SetAccountWithPropertyRightOfUseOutput } from '../model';
// @ts-ignore
import { SigningInfoDto } from '../model';
// @ts-ignore
import { UpdatePowerOfAttorneyRequestDto } from '../model';
/**
 * BankIdApi - axios parameter creator
 * @export
 */
export const BankIdApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check postponed-date - if not set, set it to current date + postponed-days
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdCheckBankVerificationPostponeDate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankId/check-verify-postponed-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing delegation
         * @param {string} delegationRefCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdDeleteDelegation: async (delegationRefCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'delegationRefCode' is not null or undefined
            assertParamExists('bankIdDeleteDelegation', 'delegationRefCode', delegationRefCode)
            const localVarPath = `/api/v1/bankId/delete-delegation/{delegationRefCode}`
                .replace(`{${"delegationRefCode"}}`, encodeURIComponent(String(delegationRefCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if user has verified by bankid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdDoUserNeedToVerifyWithBankId: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankId/do-user-need-to-verify-with-bankId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Temporary
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetContactByEmail: async (email?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankId/get-contact-by-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get verified link
         * @param {GetLinkVerifiedSigningInput} [getLinkVerifiedSigningInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetLinkVerifiedSigning: async (getLinkVerifiedSigningInput?: GetLinkVerifiedSigningInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankId/get-link-verified-signing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getLinkVerifiedSigningInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of organizations with RoU
         * @param {GetRightOfUseListInput} [getRightOfUseListInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetOrganizationWithRightOfUse: async (getRightOfUseListInput?: GetRightOfUseListInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankId/get-organization-with-right-of-use`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getRightOfUseListInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get permissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetPermissions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankId/get-permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contact by email
         * @param {GetPersonInfoFromEmailDto} [getPersonInfoFromEmailDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetPersonFromEmail: async (getPersonInfoFromEmailDto?: GetPersonInfoFromEmailDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankId/get-person-from-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPersonInfoFromEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get PoA ref code
         * @param {string} delegationRefCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetPowerOfAttorney: async (delegationRefCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'delegationRefCode' is not null or undefined
            assertParamExists('bankIdGetPowerOfAttorney', 'delegationRefCode', delegationRefCode)
            const localVarPath = `/api/v1/bankId/power-of-attorney/{delegationRefCode}`
                .replace(`{${"delegationRefCode"}}`, encodeURIComponent(String(delegationRefCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get PoA contact
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetPowersOfAttorneyContact: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankId/powers-of-attorney/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get info about property
         * @param {GetPropertyInfoRequestDto} [getPropertyInfoRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetPropertyInfo: async (getPropertyInfoRequestDto?: GetPropertyInfoRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankId/get-property-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPropertyInfoRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account based on account ID.
         * @param {string} orgNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetSigningCombinations: async (orgNumber: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNumber' is not null or undefined
            assertParamExists('bankIdGetSigningCombinations', 'orgNumber', orgNumber)
            const localVarPath = `/api/v1/bankId/organization-with-signing-combinations/{orgNumber}`
                .replace(`{${"orgNumber"}}`, encodeURIComponent(String(orgNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account based on account ID.
         * @param {GetJuristicInfoFromBRREGInput} [getJuristicInfoFromBRREGInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetSigningCombinationsPost: async (getJuristicInfoFromBRREGInput?: GetJuristicInfoFromBRREGInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankId/organization-with-signing-combinations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getJuristicInfoFromBRREGInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set account with RoU
         * @param {SetAccountWithPropertyRightOfUseInputRest} [setAccountWithPropertyRightOfUseInputRest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdSetAccountWithRightOfUse: async (setAccountWithPropertyRightOfUseInputRest?: SetAccountWithPropertyRightOfUseInputRest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankId/set-account-with-right-of-use`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setAccountWithPropertyRightOfUseInputRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Store bankId retrieved information
         * @param {BankIdIdTokensRequestDto} [bankIdIdTokensRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdStoreAndVerifyBankIdInfo: async (bankIdIdTokensRequestDto?: BankIdIdTokensRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankId/store-bankId-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bankIdIdTokensRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update PoA
         * @param {UpdatePowerOfAttorneyRequestDto} [updatePowerOfAttorneyRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdUpdatePowerOfAttorney: async (updatePowerOfAttorneyRequestDto?: UpdatePowerOfAttorneyRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankId/update-power-of-attorney`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePowerOfAttorneyRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify bankId information
         * @param {BankIdIdTokensRequestDto} [bankIdIdTokensRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdVerifyBankIdInfo: async (bankIdIdTokensRequestDto?: BankIdIdTokensRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankId/verify-bankId-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bankIdIdTokensRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankIdApi - functional programming interface
 * @export
 */
export const BankIdApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BankIdApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check postponed-date - if not set, set it to current date + postponed-days
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdCheckBankVerificationPostponeDate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankIdVerificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdCheckBankVerificationPostponeDate(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdCheckBankVerificationPostponeDate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete existing delegation
         * @param {string} delegationRefCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdDeleteDelegation(delegationRefCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelDelegationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdDeleteDelegation(delegationRefCode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdDeleteDelegation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Check if user has verified by bankid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdDoUserNeedToVerifyWithBankId(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdDoUserNeedToVerifyWithBankId(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdDoUserNeedToVerifyWithBankId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Temporary
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdGetContactByEmail(email?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactModelComLib>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdGetContactByEmail(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdGetContactByEmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get verified link
         * @param {GetLinkVerifiedSigningInput} [getLinkVerifiedSigningInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdGetLinkVerifiedSigning(getLinkVerifiedSigningInput?: GetLinkVerifiedSigningInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLinkVerifiedSigningOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdGetLinkVerifiedSigning(getLinkVerifiedSigningInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdGetLinkVerifiedSigning']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get list of organizations with RoU
         * @param {GetRightOfUseListInput} [getRightOfUseListInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdGetOrganizationWithRightOfUse(getRightOfUseListInput?: GetRightOfUseListInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRightOfUseListOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdGetOrganizationWithRightOfUse(getRightOfUseListInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdGetOrganizationWithRightOfUse']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get permissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdGetPermissions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdGetPermissions(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdGetPermissions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get contact by email
         * @param {GetPersonInfoFromEmailDto} [getPersonInfoFromEmailDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdGetPersonFromEmail(getPersonInfoFromEmailDto?: GetPersonInfoFromEmailDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EssentialPersonInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdGetPersonFromEmail(getPersonInfoFromEmailDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdGetPersonFromEmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get PoA ref code
         * @param {string} delegationRefCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdGetPowerOfAttorney(delegationRefCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPowerOfAttorneyOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdGetPowerOfAttorney(delegationRefCode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdGetPowerOfAttorney']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get PoA contact
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdGetPowersOfAttorneyContact(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPowersOfAttorneyContactOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdGetPowersOfAttorneyContact(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdGetPowersOfAttorneyContact']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get info about property
         * @param {GetPropertyInfoRequestDto} [getPropertyInfoRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdGetPropertyInfo(getPropertyInfoRequestDto?: GetPropertyInfoRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPropertyInfoResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdGetPropertyInfo(getPropertyInfoRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdGetPropertyInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get account based on account ID.
         * @param {string} orgNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdGetSigningCombinations(orgNumber: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SigningInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdGetSigningCombinations(orgNumber, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdGetSigningCombinations']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get account based on account ID.
         * @param {GetJuristicInfoFromBRREGInput} [getJuristicInfoFromBRREGInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdGetSigningCombinationsPost(getJuristicInfoFromBRREGInput?: GetJuristicInfoFromBRREGInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SigningInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdGetSigningCombinationsPost(getJuristicInfoFromBRREGInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdGetSigningCombinationsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Set account with RoU
         * @param {SetAccountWithPropertyRightOfUseInputRest} [setAccountWithPropertyRightOfUseInputRest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdSetAccountWithRightOfUse(setAccountWithPropertyRightOfUseInputRest?: SetAccountWithPropertyRightOfUseInputRest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetAccountWithPropertyRightOfUseOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdSetAccountWithRightOfUse(setAccountWithPropertyRightOfUseInputRest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdSetAccountWithRightOfUse']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Store bankId retrieved information
         * @param {BankIdIdTokensRequestDto} [bankIdIdTokensRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdStoreAndVerifyBankIdInfo(bankIdIdTokensRequestDto?: BankIdIdTokensRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankIdPersonInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdStoreAndVerifyBankIdInfo(bankIdIdTokensRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdStoreAndVerifyBankIdInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update PoA
         * @param {UpdatePowerOfAttorneyRequestDto} [updatePowerOfAttorneyRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdUpdatePowerOfAttorney(updatePowerOfAttorneyRequestDto?: UpdatePowerOfAttorneyRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePowerOfAttorneyOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdUpdatePowerOfAttorney(updatePowerOfAttorneyRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdUpdatePowerOfAttorney']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Verify bankId information
         * @param {BankIdIdTokensRequestDto} [bankIdIdTokensRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdVerifyBankIdInfo(bankIdIdTokensRequestDto?: BankIdIdTokensRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankIdVerificationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdVerifyBankIdInfo(bankIdIdTokensRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BankIdApi.bankIdVerifyBankIdInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * BankIdApi - factory interface
 * @export
 */
export const BankIdApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BankIdApiFp(configuration)
    return {
        /**
         * 
         * @summary Check postponed-date - if not set, set it to current date + postponed-days
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdCheckBankVerificationPostponeDate(options?: any): AxiosPromise<BankIdVerificationDto> {
            return localVarFp.bankIdCheckBankVerificationPostponeDate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete existing delegation
         * @param {string} delegationRefCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdDeleteDelegation(delegationRefCode: string, options?: any): AxiosPromise<CancelDelegationOutput> {
            return localVarFp.bankIdDeleteDelegation(delegationRefCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if user has verified by bankid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdDoUserNeedToVerifyWithBankId(options?: any): AxiosPromise<boolean> {
            return localVarFp.bankIdDoUserNeedToVerifyWithBankId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Temporary
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetContactByEmail(email?: string, options?: any): AxiosPromise<Array<ContactModelComLib>> {
            return localVarFp.bankIdGetContactByEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get verified link
         * @param {GetLinkVerifiedSigningInput} [getLinkVerifiedSigningInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetLinkVerifiedSigning(getLinkVerifiedSigningInput?: GetLinkVerifiedSigningInput, options?: any): AxiosPromise<GetLinkVerifiedSigningOutput> {
            return localVarFp.bankIdGetLinkVerifiedSigning(getLinkVerifiedSigningInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of organizations with RoU
         * @param {GetRightOfUseListInput} [getRightOfUseListInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetOrganizationWithRightOfUse(getRightOfUseListInput?: GetRightOfUseListInput, options?: any): AxiosPromise<GetRightOfUseListOutput> {
            return localVarFp.bankIdGetOrganizationWithRightOfUse(getRightOfUseListInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get permissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetPermissions(options?: any): AxiosPromise<Array<PermissionModel>> {
            return localVarFp.bankIdGetPermissions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contact by email
         * @param {GetPersonInfoFromEmailDto} [getPersonInfoFromEmailDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetPersonFromEmail(getPersonInfoFromEmailDto?: GetPersonInfoFromEmailDto, options?: any): AxiosPromise<EssentialPersonInfoResponse> {
            return localVarFp.bankIdGetPersonFromEmail(getPersonInfoFromEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get PoA ref code
         * @param {string} delegationRefCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetPowerOfAttorney(delegationRefCode: string, options?: any): AxiosPromise<GetPowerOfAttorneyOutput> {
            return localVarFp.bankIdGetPowerOfAttorney(delegationRefCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get PoA contact
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetPowersOfAttorneyContact(options?: any): AxiosPromise<GetPowersOfAttorneyContactOutput> {
            return localVarFp.bankIdGetPowersOfAttorneyContact(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get info about property
         * @param {GetPropertyInfoRequestDto} [getPropertyInfoRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetPropertyInfo(getPropertyInfoRequestDto?: GetPropertyInfoRequestDto, options?: any): AxiosPromise<GetPropertyInfoResponseDto> {
            return localVarFp.bankIdGetPropertyInfo(getPropertyInfoRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get account based on account ID.
         * @param {string} orgNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetSigningCombinations(orgNumber: string, options?: any): AxiosPromise<SigningInfoDto> {
            return localVarFp.bankIdGetSigningCombinations(orgNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get account based on account ID.
         * @param {GetJuristicInfoFromBRREGInput} [getJuristicInfoFromBRREGInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdGetSigningCombinationsPost(getJuristicInfoFromBRREGInput?: GetJuristicInfoFromBRREGInput, options?: any): AxiosPromise<SigningInfoDto> {
            return localVarFp.bankIdGetSigningCombinationsPost(getJuristicInfoFromBRREGInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set account with RoU
         * @param {SetAccountWithPropertyRightOfUseInputRest} [setAccountWithPropertyRightOfUseInputRest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdSetAccountWithRightOfUse(setAccountWithPropertyRightOfUseInputRest?: SetAccountWithPropertyRightOfUseInputRest, options?: any): AxiosPromise<SetAccountWithPropertyRightOfUseOutput> {
            return localVarFp.bankIdSetAccountWithRightOfUse(setAccountWithPropertyRightOfUseInputRest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Store bankId retrieved information
         * @param {BankIdIdTokensRequestDto} [bankIdIdTokensRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdStoreAndVerifyBankIdInfo(bankIdIdTokensRequestDto?: BankIdIdTokensRequestDto, options?: any): AxiosPromise<BankIdPersonInfoDto> {
            return localVarFp.bankIdStoreAndVerifyBankIdInfo(bankIdIdTokensRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update PoA
         * @param {UpdatePowerOfAttorneyRequestDto} [updatePowerOfAttorneyRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdUpdatePowerOfAttorney(updatePowerOfAttorneyRequestDto?: UpdatePowerOfAttorneyRequestDto, options?: any): AxiosPromise<CreatePowerOfAttorneyOutput> {
            return localVarFp.bankIdUpdatePowerOfAttorney(updatePowerOfAttorneyRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify bankId information
         * @param {BankIdIdTokensRequestDto} [bankIdIdTokensRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdVerifyBankIdInfo(bankIdIdTokensRequestDto?: BankIdIdTokensRequestDto, options?: any): AxiosPromise<BankIdVerificationModel> {
            return localVarFp.bankIdVerifyBankIdInfo(bankIdIdTokensRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BankIdApi - object-oriented interface
 * @export
 * @class BankIdApi
 * @extends {BaseAPI}
 */
export class BankIdApi extends BaseAPI {
    /**
     * 
     * @summary Check postponed-date - if not set, set it to current date + postponed-days
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdCheckBankVerificationPostponeDate(options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdCheckBankVerificationPostponeDate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete existing delegation
     * @param {string} delegationRefCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdDeleteDelegation(delegationRefCode: string, options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdDeleteDelegation(delegationRefCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if user has verified by bankid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdDoUserNeedToVerifyWithBankId(options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdDoUserNeedToVerifyWithBankId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Temporary
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdGetContactByEmail(email?: string, options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdGetContactByEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get verified link
     * @param {GetLinkVerifiedSigningInput} [getLinkVerifiedSigningInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdGetLinkVerifiedSigning(getLinkVerifiedSigningInput?: GetLinkVerifiedSigningInput, options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdGetLinkVerifiedSigning(getLinkVerifiedSigningInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of organizations with RoU
     * @param {GetRightOfUseListInput} [getRightOfUseListInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdGetOrganizationWithRightOfUse(getRightOfUseListInput?: GetRightOfUseListInput, options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdGetOrganizationWithRightOfUse(getRightOfUseListInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get permissions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdGetPermissions(options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdGetPermissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contact by email
     * @param {GetPersonInfoFromEmailDto} [getPersonInfoFromEmailDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdGetPersonFromEmail(getPersonInfoFromEmailDto?: GetPersonInfoFromEmailDto, options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdGetPersonFromEmail(getPersonInfoFromEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get PoA ref code
     * @param {string} delegationRefCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdGetPowerOfAttorney(delegationRefCode: string, options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdGetPowerOfAttorney(delegationRefCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get PoA contact
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdGetPowersOfAttorneyContact(options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdGetPowersOfAttorneyContact(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get info about property
     * @param {GetPropertyInfoRequestDto} [getPropertyInfoRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdGetPropertyInfo(getPropertyInfoRequestDto?: GetPropertyInfoRequestDto, options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdGetPropertyInfo(getPropertyInfoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get account based on account ID.
     * @param {string} orgNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdGetSigningCombinations(orgNumber: string, options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdGetSigningCombinations(orgNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get account based on account ID.
     * @param {GetJuristicInfoFromBRREGInput} [getJuristicInfoFromBRREGInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdGetSigningCombinationsPost(getJuristicInfoFromBRREGInput?: GetJuristicInfoFromBRREGInput, options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdGetSigningCombinationsPost(getJuristicInfoFromBRREGInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set account with RoU
     * @param {SetAccountWithPropertyRightOfUseInputRest} [setAccountWithPropertyRightOfUseInputRest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdSetAccountWithRightOfUse(setAccountWithPropertyRightOfUseInputRest?: SetAccountWithPropertyRightOfUseInputRest, options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdSetAccountWithRightOfUse(setAccountWithPropertyRightOfUseInputRest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Store bankId retrieved information
     * @param {BankIdIdTokensRequestDto} [bankIdIdTokensRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdStoreAndVerifyBankIdInfo(bankIdIdTokensRequestDto?: BankIdIdTokensRequestDto, options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdStoreAndVerifyBankIdInfo(bankIdIdTokensRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update PoA
     * @param {UpdatePowerOfAttorneyRequestDto} [updatePowerOfAttorneyRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdUpdatePowerOfAttorney(updatePowerOfAttorneyRequestDto?: UpdatePowerOfAttorneyRequestDto, options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdUpdatePowerOfAttorney(updatePowerOfAttorneyRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify bankId information
     * @param {BankIdIdTokensRequestDto} [bankIdIdTokensRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankIdApi
     */
    public bankIdVerifyBankIdInfo(bankIdIdTokensRequestDto?: BankIdIdTokensRequestDto, options?: RawAxiosRequestConfig) {
        return BankIdApiFp(this.configuration).bankIdVerifyBankIdInfo(bankIdIdTokensRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}

