import {useBankIDStore} from "~/stores/bankID";
import type {AxiosInstanceWithExtra} from '~/plugins/2.axios';

/**
 * Custom axios instance with refresh token logic, and auth header (using bankid access_token)
 */
export default defineNuxtPlugin(nuxtApp => {
	// @ts-ignore for now
	const bankIdAxios = nuxtApp.$axios.create({
		withCredentials: false,
	}) as AxiosInstanceWithExtra

	bankIdAxios.interceptors.request.use(async (config) => {
		const bankIDStore = useBankIDStore()
		const tokens = await bankIDStore.getTokensRefreshIfNeeded()
		if (tokens) {
			config.headers.Authorization = `Bearer ${tokens.access_token}`;
		}
		return config;
	})

	return {
		provide: {
			bankIdAxios,
		}
	}
})
