let hlCache = new Map()

const highlightCssClass = 'bg-blue-100'

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.vueApp.directive('highlight', {
        beforeMount(el, { value }) {
            hlCache.set(el, JSON.stringify(value))
        },
        updated(el, { value }) {
            if (hlCache.get(el) && hlCache.get(el) !== JSON.stringify(value)) {
                hlCache.set(el, JSON.stringify(value))
                el.classList.remove(highlightCssClass)
                el.classList.add(highlightCssClass)

                setTimeout(() => {
                    el.classList.add('transition-colors', 'ease-out', 'duration-1000')
                    el.classList.remove(highlightCssClass)
                    setTimeout(() => {
                        el.classList.remove('transition-colors', 'ease-out', 'duration-1000')
                    }, 1000)
                }, 2000)
            }
        },
        unmounted(el) {
            if (hlCache && typeof hlCache.delete === 'function') {
                hlCache.delete(el)
            }
        },
    })
})
