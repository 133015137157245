// @ts-nocheck
import {createUpload} from '@websanova/vue-upload/src/v3';
import {getAxiosConfig} from '~/store/constants/api-url';
import {CancelToken} from 'axios';


export default defineNuxtPlugin((nuxtApp) => {
    const {$axios} = useNuxtApp();
    const upload: any = createUpload({
        plugins: {},
        drivers: {
            http: {
                call: (data) => {
                    let cancel
                    let request = $axios
                        .$post(data.url, data.body, Object.assign({}, getAxiosConfig(), {
                            // https://github.com/axios/axios#cancellation
                            cancelToken: new CancelToken((c) => {
                                cancel = c
                            }),
                            onUploadProgress: (event) => {
                                data.progress(event)
                            },
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            }
                        }))
                        .then((response) => {
                            data.success(response)
                        })
                        .catch((error) => {
                            data.error(error)
                        })

                    request.abort = () => {
                        cancel()
                    }

                    return request
                },
            },
        },
        options: {
            parseErrors: (error) => {
                return [{
                    code: (error.response && error.response.status) || 0,
                    msg: error.response && ((error.response.data != null && error.response.data.Message) || error.response.statusText),
                }];
            },
        }
    })
    nuxtApp.vueApp.use(upload);
    return {
        provide: {
            upload: upload,
        },
    };
})
