// Generated by @nuxtjs/svg-sprite
export const sprites = {
  "icons": () => import("/home/vsts/work/1/s/assets/sprite/gen/icons.svg").then(r => r.default || r),
  "spot": () => import("/home/vsts/work/1/s/assets/sprite/gen/spot.svg").then(r => r.default || r)
}
export const spriteClass = "";

export const spriteClassPrefix = "";

export const defaultSprite = "icons";
