import {defineStore} from 'pinia'

interface State {
    activeAccount?: string | null,
    activeAccountParent?: string | null,
}

export const useAccountStore = defineStore('account', {
  state: (): State => ({
      // activeAccount can in some cases be an entrepreneur "team", and not an actual account.
      // in that case, activeAccountParent will be the actual accountId
      activeAccount: null,
      activeAccountParent: null,
	}),
	actions: {
        reset() {
            this.activeAccount = null
            this.activeAccountParent = null
        },

        /**
         * Should primarily be used by accountsService. For usage in components, see accountsService.setActiveAccount()
         * @param accountId
         * @param account
         */
        async setActive(accountId: string, account: any): Promise<void> {
            if (account.EntrepreneurId) {
                this.activeAccountParent = account.EntrepreneurId;
            } else {
                this.activeAccountParent = null;
            }
            this.activeAccount = accountId;
        },
	}
})
