import {
    type DehydratedState,
    QueryClient,
    VueQueryPlugin,
    type VueQueryPluginOptions
} from "@tanstack/vue-query";

export default defineNuxtPlugin((nuxt) => {
    const vueQueryState = useState<DehydratedState | null>('vue-query')

    // Modify your Vue Query global settings here
    const queryClient = new QueryClient({
        defaultOptions: { queries: { staleTime: 1000 } },
    })
    const options: VueQueryPluginOptions = { queryClient }

    nuxt.vueApp.use(VueQueryPlugin, options)
})