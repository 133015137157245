const API_AXIOS_CONFIG = {
	withCredentials: true,
}

export function getAxiosConfig() {
	const $config = useRuntimeConfig();
	return {
		...API_AXIOS_CONFIG,
		baseURL: $config.public.apiBaseUrl,
	}
}