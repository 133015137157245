import {generateUUID} from '~/helpers/uniqueId';

// bunch of components are relying on _uid to be present
// this mixin will add a _uid to all components
export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.mixin({
        data() {
            return {
                _uid: generateUUID()
            }
        }
    })
})
